import React from 'react';
import Layout from "../components/layout"
import Parser from 'html-react-parser';

export default function BlogTemplate({ location, pageContext }) {
    // console.log(pageContext)
    return (
        <Layout location={location}>
            <div className="flex flex-col gap-8 pt-16 justify-center items-center px-8 md:px-16">
                <h1 className="font-heading1" max-w-6xl > Legal</h1>
   
                <div className="font-body1 blog  max-w-6xl">
                    {Parser(pageContext.legal)}
                </div>

            </div>
        </Layout>
    )
}
